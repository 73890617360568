import moment, { Moment } from 'moment-timezone';

import ApiPaymentTransaction from 'types/api/PaymentTransaction';

import Currency from 'models/Currency';
import DiscountCode from 'models/DiscountCode';
import { Timezone } from 'models/Market';
import Model from 'models/Model';
import Order from 'models/Order';

import { getFormattedDate } from 'utils/date';
import { changeFromCent } from 'utils/math';

export enum Statuses {
    New = 'new',
    Pending = 'pending',
    Completed = 'completed',
    Canceled = 'canceled',
    Refunded = 'refunded',
    Error = 'error',
    Undefined = 'undefined',
}

export enum PaymentProvider {
    Cash = 'cash',
    Blik = 'blik',
    Przelewy24 = 'przelewy24',
    Stripe = 'stripe',
    PayPal = 'paypal',
    PayPo = 'paypo',
    BlikPayLater = 'blikPayLater',
}

export enum PaymentProviderChannel {
    InstallmentMethods = 128,
    GoogleAndApplePay = 1,
    AllMethods = 16,
}

export interface PaymentProviderData {
    displayName: string;
    id: string;
    name: string;
    slug: string;
}

interface User {
    id: string;
    role: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    hasPassword: boolean;
}

export interface OrderPaymentData {
    id?: string;
    cartId?: string;
    discountCode?: DiscountCode;
    currency: Currency;
    paymentProvider?: string;
    buyNow?: boolean;
    priceGross?: number;
    order: {
        price?: number;
        user: User;
        items: {
            currency: Currency;
            id: string;
            price: number;
            productId: number;
            productName: string;
            productType: string;
            quantity: number;
        };
        discountCode?: any;
        cartId?: string;
    }
}


export default class PaymentTransaction implements Model {
    id: string;
    createdAt: Moment;
    createdAtDisplay: string;
    currency: Currency;
    price: number;
    priceDisplay: string;
    externalId: string;
    continueUrl: string;
    redirectUrl: string;
    status: Statuses;
    orderPaymentId: string;
    paymentProvider: PaymentProviderData;
    orderPayment: OrderPaymentData;
    order: Order;

    constructor(data: ApiPaymentTransaction) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).tz(Timezone);
        this.createdAtDisplay = this.createdAt && getFormattedDate(this.createdAt);
        this.price = changeFromCent(data.price);
        this.externalId = data.externalId;
        this.continueUrl = data.continueUrl;
        this.redirectUrl = data.redirectUrl;
        this.status = data.status;
        this.orderPaymentId = data.orderPaymentId;
        this.paymentProvider = data.paymentProvider;
        this.orderPayment = data.orderPayment;
        this.order = data.order;
    }
}

import moment, { Moment } from 'moment-timezone';

import ApiCart from 'types/api/Cart';

import CartItem from 'models/CartItem';
import Currency from 'models/Currency';
import DiscountCode, { Types } from 'models/DiscountCode';
import { Timezone } from 'models/Market';
import Model from 'models/Model';

import { getFormattedDate } from 'utils/date';
import { changeFromCent, displayPrice } from 'utils/math';

import GtmVoucherCheckout from './GtmVoucherCheckout';

export interface Pricing {
    hasPromo: boolean;

    marketingPrice: number;
    marketingPriceDisplay: string;

    originalPrice: number;
    originalPriceDisplay: string;

    originalPriceTotal: number; //With unaccepted items
    originalPriceTotalDisplay: string;

    promoPrice: number;
    promoPriceDisplay: string;
    promoPercent: number;
    promoPercentDisplay: string;

    discountCodePercent: number;
    discountCodePercentDisplay: string;
    discountCodePrice: number;
    discountCodePriceDisplay: string;
    discountCodeValue: string;

    finalPriceTotal: number; //With unaccepted items
    finalPriceTotalDisplay: string;

    finalPrice: number;
    finalPriceDisplay: string;

    hasSavings: boolean;
    savingsPercent: number;
    savingsPercentDisplay: string;
    savingsValue: number;
    savingsValueDisplay: string;

    itemsCombinedPrice: number;
    itemsCombinedPriceDisplay: string;
    promoSavings: number;
    promoSavingsDisplay: string;
}

export default class Cart implements Model {
    id: string;
    createdAt: Moment;
    createdAtDisplay: string;
    currency: Currency;
    items: CartItem[];
    discountCode: DiscountCode;
    price: number;
    priceDisplay: string;
    priceTotal: number;
    priceTotalDisplay: string;
    highestPrice: number;
    highestPriceTotal: number;
    originalPrice: number;
    originalPriceTotal: number;
    discountCodeValue: number;
    pricing: Pricing;

    constructor(data: ApiCart) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).tz(Timezone);
        this.createdAtDisplay = this.createdAt && getFormattedDate(this.createdAt);
        this.currency = data.currency && new Currency(data.currency) || null;
        this.items = Array.isArray(data.items) && data.items.map(item => new CartItem(item, this.currency, data?.products, data?.bundles));
        this.discountCode = data.discountCode && new DiscountCode(data.discountCode);
        this.price = changeFromCent(data.price);
        this.priceDisplay = displayPrice(changeFromCent(data.price), this.currency);
        this.priceTotal = changeFromCent(data.priceTotal);
        this.priceTotalDisplay = displayPrice(changeFromCent(data.priceTotal), this.currency);
        this.highestPrice = changeFromCent(data.highestPrice);
        this.highestPriceTotal = changeFromCent(data.highestPriceTotal);
        this.originalPrice = changeFromCent(data.originalPrice);
        this.originalPriceTotal = changeFromCent(data.originalPriceTotal);
        this.discountCodeValue = changeFromCent(data.discountCodeValue);
        this.pricing = this.getTotalPrice(this.items, this.discountCode);
    }

    getTotalPrice = (items: CartItem[], discountCode: DiscountCode): Pricing => {
        const allItems = [...items];
        items = items.filter(item => item.isAccepted);

        const pricing: Pricing = {
            hasPromo: false,
            marketingPrice: 0,
            marketingPriceDisplay: null,
            originalPrice: 0,
            originalPriceDisplay: null,
            originalPriceTotal: 0,
            originalPriceTotalDisplay: null,
            promoPrice: 0,
            promoPriceDisplay: null,
            promoPercent: 0,
            promoPercentDisplay: null,
            discountCodePrice: 0,
            discountCodePriceDisplay: null,
            discountCodePercent: 0,
            discountCodePercentDisplay: null,
            discountCodeValue: null,
            finalPrice: 0,
            finalPriceDisplay: null,
            finalPriceTotal: 0,
            finalPriceTotalDisplay: null,
            hasSavings: false,
            savingsPercent: 0,
            savingsPercentDisplay: null,
            savingsValue: 0,
            savingsValueDisplay: null,
            itemsCombinedPrice: 0,
            itemsCombinedPriceDisplay: null,
            promoSavings: 0,
            promoSavingsDisplay: null,
        };

        // we need to display original price withouy any promos and promo value separately
        const itemsOriginalPriceWithoutAnyPromotions = items.map(item => {
            if (item.pricing?.marketingPrice) {
                return item.pricing.marketingPrice;
            }
            return item.pricing.originalPrice;
        });

        pricing.itemsCombinedPrice = itemsOriginalPriceWithoutAnyPromotions.reduce((total, item) => total + item, 0);
        pricing.itemsCombinedPriceDisplay = displayPrice(pricing.itemsCombinedPrice, this.currency);

        pricing.marketingPrice = items.reduce((total, item) => total + item.pricing.marketingPrice, 0);
        pricing.marketingPriceDisplay = displayPrice(pricing.marketingPrice, this.currency);

        pricing.originalPrice = items.reduce((total, item) => total + item.pricing.originalPrice, 0);
        pricing.originalPriceDisplay = displayPrice(pricing.originalPrice, this.currency);

        pricing.originalPriceTotal = allItems.reduce((total, item) => total + item.pricing.originalPrice, 0);
        pricing.originalPriceTotalDisplay = displayPrice(pricing.originalPriceTotal, this.currency);

        // We need to show the difference (%) between the items combined original price and the items combined reduced price.
        pricing.promoSavings = 100 - Math.round((pricing.itemsCombinedPrice * 100) / pricing.originalPrice);
        pricing.promoSavingsDisplay = pricing.promoSavings ? `${pricing.promoSavings}%` : null;

        pricing.promoPrice = items.reduce((total, item) => total + item.pricing.promoPrice, 0);
        pricing.promoPriceDisplay = displayPrice(pricing.promoPrice, this.currency);
        pricing.promoPercent = this.getPromoPercent(pricing.marketingPrice, pricing.promoPrice);
        pricing.promoPercentDisplay = `${pricing.promoPercent}%`;

        pricing.finalPrice = this.price;
        pricing.finalPriceTotal = this.priceTotal;

        // get savings but do not count discountCode
        pricing.savingsValue = this.getPromoValue(pricing.marketingPrice, pricing.finalPrice);
        pricing.savingsValueDisplay = displayPrice(pricing.savingsValue, this.currency);

        if (discountCode) {
            let discountPrice = 0;
            switch (discountCode.discountType) {
                case Types.Amount:
                    discountPrice = discountCode.discountValue;
                    break;
                case Types.Percentage:
                    discountPrice = pricing.finalPrice * (discountCode.discountValue / 100);
                    break;
            }

            if (discountPrice < 0) discountPrice = 0;
            if (discountPrice > pricing.originalPrice) discountPrice = pricing.originalPrice;

            pricing.discountCodePrice = discountPrice;
            pricing.discountCodePriceDisplay = displayPrice(pricing.discountCodePrice, this.currency);
            pricing.discountCodePercent = this.getPromoPercent(pricing.finalPrice, discountPrice);
            pricing.discountCodePercentDisplay = `${pricing.discountCodePercent}%`;
            pricing.discountCodeValue = this.getDiscountCodeValue(discountCode, pricing);
        }

        pricing.finalPrice = pricing.finalPrice > 0 && pricing.finalPrice || 0;
        pricing.finalPriceDisplay = displayPrice(pricing.finalPrice, this.currency);
        pricing.finalPriceTotal = pricing.finalPriceTotal > 0 && pricing.finalPriceTotal || 0;
        pricing.finalPriceTotalDisplay = displayPrice(pricing.finalPriceTotal, this.currency);

        pricing.savingsPercent = this.getPromoPercent(pricing.marketingPrice, pricing.finalPrice);
        pricing.savingsPercentDisplay = pricing.savingsPercent ? `${pricing.savingsPercent}%` : null;
        pricing.hasSavings = pricing.savingsPercent > 0;

        if (pricing.originalPrice > pricing.finalPrice) {
            pricing.hasPromo = true;
        }

        return pricing;
    };

    getDiscountCodeValue = (discountCode: DiscountCode, pricing: Pricing) => {
        if (discountCode?.discountType === 'amount') {
            return pricing?.discountCodePriceDisplay ? pricing.discountCodePriceDisplay : null;
        }
        if (discountCode?.discountType === 'percentage') {
            return pricing?.discountCodePercent ? `${100 - pricing.discountCodePercent}%` : null;
        }

        return null;
    };

    getCartProductsTrackingProps = () => {
        return this.items
            .filter(item => item.isAccepted)
            .map(item => {
                if (item.itemType === 'voucher') return new GtmVoucherCheckout(item);
                return item.getTrackingProps();
            });
    };

    getPromoPercent = (price: number, promoPrice: number): number => {
        if (!promoPrice) return 0;

        const difference = price - promoPrice;
        const promoPercent = (difference / price) * 100;

        return Math.round(promoPercent);
    };

    getPromoValue = (price: number, promoPrice: number): number => {
        if (!promoPrice) return 0;
        return price - promoPrice;
    };
}

export const fromCartItem = (items: CartItem[], discountCode: DiscountCode, currency: Currency): Cart => {
    return new Cart(
        {
            highestPrice: 0,
            highestPriceTotal: 0,
            id: 'discount-code-id',
            createdAt: moment().toISOString(),
            currency: currency.origin,
            items: items.map(item => item.origin),
            discountCode,
            price: discountCode?.cart?.price,
            priceTotal: discountCode?.cart?.priceTotal,
            originalPrice: discountCode?.cart?.originalPrice,
            originalPriceTotal: discountCode?.cart?.originalPriceTotal,
            discountCodeValue: discountCode?.cart?.discountCodeValue,
        },
    );
};
